/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
/*
html {
   zoom: 0.7; 
   
  }
  */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: 'Roboto', sans-serif !important; */
  font-family: "Inter", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: whitesmoke;
}
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  background-color: #fafafa;
}
.capitalize {
  text-transform: capitalize;
}

/* triage pdf */

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

p.site-description-item-profile-p {
  display: block;
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
#capture .ant-card-body {
  padding: 0.5rem 1.5rem 1.5rem;
}
#capture .ant-divider-horizontal {
  margin: 12px 0;
}

/* @media print {
  #capture .ant-card-body {
    padding: 0.5rem 1.5rem;
    break-after: avoid;
    overflow: hidden !important;
  }
  #capture:last-child {
     page-break-after: avoid;
}

  @page {
    size: A4;
    break-after: avoid;
    break-inside: avoid;
    marks: crop;
      bleed: 1cm;

  }

  @page: right {
    @bottom-right {
      content: counter(page) " of " counter(pages);
    }
  }
  .site-description-item-profile-p {
    break-after: avoid;
  }
} */
.bedCard {
  text-align: center;
}

.bedCardOccupied {
  text-align: center;
}
.bedCardOccupied:hover {
  cursor: pointer;
  text-align: center;
  /* box-shadow: 0px 0px 2px 0px darkgrey; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
.bedTitle {
  font-size: 1.5rem;
  margin: 0.625rem 0rem 0rem;
}

.demographic .ant-input-number,
.comorbidities .ant-input-number {
  width: 200px !important;
}
.demographic .ant-input-number,
.demographic .ant-picker .comorbidities .ant-input-number,
.comorbidities .ant-picker {
  width: 100% !important;
}

/* responsibilities */
.card--responsibility {
  width: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
/* .card--responsibility.done {
 background-color: gray;
} */
.card--responsibility:hover {
  cursor: pointer;
  background-color: #1890ff;
  color: white;
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) */
}
.card--responsibility:hover .card--responsibility-name {
  font-size: 0.825rem;
  padding-left: 1rem;
  color: white;
}
.card--responsibility:hover .ant-card-meta-title {
  font-size: 1.5rem;
  padding-left: 1rem;
  color: white;
}
.card--responsibility .ant-card-meta-title {
  font-size: 1.5rem;
  padding-left: 1rem;
  color: black;
}
.card--responsibility-name {
  font-size: 0.825rem;
  padding-left: 1rem;
  color: black;
}
/* table, th, td {
  border: 1px solid black;
} */

.invResults .ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #d0d0d0;
}
.landing--patientList .ant-table-title {
  text-align: right;
}
.landing--patientList .ant-table-content {
  font-size: 1.2rem !important;
}

.demographic label {
  font-size: 1.125rem !important;
  font-weight: 500;
}
/* treatment plan */

.donotadminister.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #ff4d4f !important;
}
.donotadminister.ant-radio-button-wrapper-checked {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.networkDisconnected {
  filter: saturate(0);
}
.com-stat-card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 2rem;
}
.com-stat {
  display: flex;
  flex-direction: column-reverse;
}
.com-stat .ant-statistic-title {
  color: black;
  font-size: 1.5rem;
  margin-top: 1.125rem;
  margin-bottom: 0px;
}
.com-stat .ant-statistic-content {
  color: black;
  font-size: 2rem;
}
.com-stat .ant-statistic-content-value-int {
  font-weight: bold;
  font-size: 2.25rem;
}
.criticalSPO2Table {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
.ant-layout-content {
  padding: 0rem 0.5rem;
}
.bedOccupancyCard .ant-card-body {
  padding: 0rem;
}

.com-stat-beds.available {
  background: rgba(178, 241, 177, 0.49);
  border-radius: 2px;
  padding: 12px;
  margin: 12px 0px;
}
.com-stat-beds.occupied {
  background: rgba(0, 115, 207, 0.19);
  border-radius: 2px;
  padding: 12px;
  margin: 12px 0px;
}
.com-stat-beds.total {
  background: #eeeeee;
  border-radius: 2px;
  padding: 12px;
  margin: 12px 0px;
}
.com-stat-beds .ant-statistic-title {
  color: black;
  font-size: 0.875rem;
}
bold {
  font-weight: 500;
}
.reportIcon svg {
  width: 64px;
  height: 64px;
}

.patient-page-header-hidden {
  display: none;
}
.ant-page-header.patient-page-header {
  margin: 0px 0px 1rem;
  padding: 16px 4px 16px 4px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;
}
.ant-page-header.patient-page-header .ant-page-header-heading-left{
  align-items: baseline;
}
.ant-page-header.patient-page-header .ant-page-header-heading-sub-title {
  color: black;
  font-size: 1rem !important;
}
.ant-page-header.patient-page-header .ant-page-header-heading-extra {
  font-weight: 500;
  font-size: 1rem;
}
.ant-page-header.patient-page-header .ant-page-header-heading {
  align-items: center;
  padding: 0px 18px;
}
.patient-page-header .ant-page-header-back-button:hover {
  /* display: none; */
  color: #1890ff !important;
}
.moderate {
  background-color: #FFA15C !important;
  color: black !important;
}
.moderate .ant-page-header-back-button{
  color: black !important;
}
.moderate .ant-page-header-heading{
  color: black !important;
}
.moderate .ant-page-header-heading-title {
  color: black !important;
  font-size: 1.7rem;
}
.moderate .ant-page-header-heading-sub-title {
  color: black !important;
}
.moderate .ant-page-header-heading-extra{
  color: black !important;
  font-weight: 500;
}
.severe {
  background-color: #FF3D37 !important;
  color: white !important;
}
.severe .ant-page-header-back-button{
  color: white !important;
}
.severe .ant-page-header-heading{
  color: white !important;
}
.severe .ant-page-header-heading-title{
  color: white !important;
  font-size: 1.7rem;
}
.severe .ant-page-header-heading-sub-title {
  color: white !important;
}
.severe .ant-page-header-heading-extra{
  color: white !important;
  font-weight: 500;
}
.normal {
  background-color: #a9a9a9 !important;
  color: black !important;
}
.normal .ant-page-header-back-button{
  color: black !important;
}
.normal .ant-page-header-heading{
  color: black !important;
}
.normal .ant-page-header-heading-title{ 
  color: black !important;
  font-size: 1.7rem;
}
.normal .ant-page-header-heading-sub-title {
  color: black !important;
}
.normal .ant-page-header-heading-extra{
  color: black !important;
  font-weight: 500;
}
.lightgrayBG .ant-drawer-header,
.lightgrayBG .ant-drawer-footer { 
  background: lightgray;
}
.hospitalDetails .ant-input-number{
  width: 100% !important;
}
.hospitalDetails label{
  /* font-size: 0.925rem !important; */
  font-weight: 500;
}
.medHistoryNew {
  padding: 0px 10px 24px !important;
}
.medHistoryNew .ant-form-item-label{
  display: none;
}
.medicationHistoryNew--labels {
  font-weight: 600;
  padding: 12px 10px !important;
}
.medicationHistoryNew--formCol {
  height: 4rem;
}
/* .medHistoryNew .medHistoryNew--row:nth-child(even) {
  background: #CCC
} */
.teal {
  background: teal !important;
  border-color: teal !important;
}
.historyOfSymptoms--form .ant-form-item {
  margin-bottom: 1.5rem;
}
.mild {
  background-color: #83f52c !important;
  color: black !important;
}
.mild .ant-page-header-back-button{
  color: black !important;
}
.mild .ant-page-header-heading{
  color: black !important;
}
.mild .ant-page-header-heading-title {
  color: black !important;
  font-size: 1.7rem;
}
.mild .ant-page-header-heading-sub-title {
  color: black !important;
}
.mild .ant-page-header-heading-extra{
  color: black !important;
  font-weight: 500;
}
.index {
  margin: 0rem 0rem 0.5rem;
}
.custom--Checkbox {
  font-size: 1rem;
  color: black;
}
.custom--Checkbox .ant-checkbox-checked .ant-checkbox-inner{
 background-color: black;
}
.custom--Checkbox .ant-checkbox-checked::after {
 border: 1px solid black;
}
.custom--Checkbox .ant-checkbox-inner{
  transform: scale(1.2) !important;
  border: 1px solid #000 !important;
}
.index--label {
  font-weight: 600;
  font-size: 2rem;
  text-transform: capitalize;
  /* background-color: darkgray;
  padding: 0.4rem 1rem; */
}
.ql-editor {
  min-height: 200px !important;
  color: black;
}
.ql-container.ql-snow, .ql-toolbar.ql-snow {
  border-color:  black !important;
}
.ant-checkbox-wrapper {
  color: black !important;
}
.ant-menu-dark .ant-menu-item-disabled {
  color:rgba(255, 255, 255, 0) !important;
  opacity: 1 !important;
}
.userResponsibilities {
  /* box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important; */
  box-shadow:0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
}
.userResponsibilities .resp {
  color: black;
  padding: 0.5rem 0.625rem;
  font-size: 1rem;
}
.userResponsibilities .resp:hover {
  color: black;
  cursor: pointer;
}
.userResponsibilities .selected {
  color: white;
  background: #1890ff;
}
.logo {
  width: 50px;
  height: 50px;
  background: url('../src/dr_logo_light.png');
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
}
.custom--List .ant-list-header{
  color: white;
  font-weight: 600;
  background-color: teal;
}
.custom--List .ant-list-item-meta-title{
  font-size: 1rem;
  color: black;
}
.mappingModal label {
  color: black;
  /* font-weight: 600; */
}
.mappingModal label:not(.ant-checkbox-wrapper):not(.ant-radio-button-wrapper):not(.ant-radio-wrapper) {
  font-weight: 600;
}
.normalizedLabel {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
}

.customizedDrawer .ant-input {
  border: 1px solid black;
}
.customizedDrawer .custom--Checkbox .ant-checkbox-wrapper {
  font-size: 1rem;
}
.ant-layout-header {
  padding: 0px 5px !important;
}

/* Color codes */
.patient_status-mild {
  background-color: #83f52c !important;
  color: black !important;
}
.patient_status-moderate {
  background-color: #FFA15C !important;
  color: black !important;
}
.patient_status-severe {
  background-color: #FF3D37 !important;
  color: white !important;
}
.patient_status-normal {
  background-color: #a9a9a9 !important;
  color: black !important;
}
/* .moderate {
  background-color: #FFA15C !important;
  color: black !important;
}
.severe {
  background-color: #FF3D37 !important;
  color: white !important;
}
.normal {
  background-color: #a9a9a9 !important;
  color: black !important;
} */

/* Medicine interaction */
/* .interactionExists .ant-select-selector{
  border: 1px solid red !important;
} */
.subscription--stats .ant-statistic-title{
  color: #000;
}
.subscription--stats .ant-statistic-content{
  color: #000;
  font-weight: 700;
  font-size: 2rem;
}

